<template>
  <LayoutSpSa>
    <v-card id="reset">
      <v-img
        id="logo-mida4"
        contain
        src="@/assets/logoMida4Ext.png"
        alt="Logo Mida4"
        max-height="100"
      ></v-img>
      <v-card-title>Reset Credenziali</v-card-title>
      <section>
        <FormSchemaNative
          ref="formReset"
          :schema="SchemaResetPassword"
          v-model="datiReset"
          v-noac
        />
        <VueRecaptcha
          id="recaptcha-container"
          ref="recaptcha"
          :sitekey="siteKey"
          :loadRecaptchaScript="true"
          @verify="onCaptchaVerified"
          @expired="onCaptchaExpired"
        />
        <button v-show="recaptchaVerified === true" @click="resetPassword">
          Reset
        </button>
      </section>
    </v-card>
  </LayoutSpSa>
</template>
<script>
const NAME = "ResetSpSa";

import LayoutSpSa from "@/components/_layout/LayoutSpSa";
import VueRecaptcha from "vue-recaptcha";

import { Forms } from "mida4-web-app-utils";
import { ErroriReset } from "mida4-fattura-rapida-spese-sanitarie";

import FormSchemaNative from "@formschema/native";
import StatusCodes from "http-status-codes";

import SchemaResetPassword from "@/entities/schemas/utente-reset-password.json";

const LINK_SCADUTO = "B2";

export default {
  name: NAME,
  props: ["token"],
  created() {
    this.datiReset.token = this.token;
  },
  mounted() {
    let f = this.$refs.formReset;
    document
      .querySelector("[name=codiceFiscale]")
      .addEventListener("input", Forms.evTargetToUpper);
    document.querySelectorAll("input:not([id^=token]").forEach((i) => {
      i.addEventListener("invalid", (e) => {
        // this.$log.warn('input non valido', e)
        e.target.classList.add("has-error");
        f.setErrorMessage(`Tutti i campi sono obbligatori: ${e.target.title}`);
      });

      i.addEventListener("input", (e) => {
        if (
          i.attributes.name.value === "codiceFiscale" &&
          i.value.length === 16
        ) {
          const stsUserIDEl = document.querySelector("[name=stsUserID");
          stsUserIDEl.value = i.value;
          stsUserIDEl.dispatchEvent(new Event("change"));
        }
        e.target.classList.remove("has-error");
        f.clearErrorMessage();
      });
    });
  },
  components: {
    LayoutSpSa,
    FormSchemaNative,
    VueRecaptcha,
  },
  data() {
    return {
      NAME: NAME,
      SchemaResetPassword: SchemaResetPassword,
      siteKey:
        process.env.NODE_ENV == "development"
          ? process.env.VUE_APP_TEST_RECAPTCHA_SITE_K
          : process.env.VUE_APP_RECAPTCHA_SITE_K,
      recaptchaVerified: false,
      recaptchaToken: null,
      datiReset: {},
    };
  },
  methods: {
    onCaptchaVerified(recaptchaToken) {
      this.recaptchaVerified = true;
      this.recaptchaToken = recaptchaToken;
    },
    onCaptchaExpired() {
      this.recaptchaVerified = false;
      this.$refs.recaptcha.reset();
    },
    getError(msg) {
      return ErroriReset.getMsg(msg);
    },
    async resetPassword() {
      if (this.$refs.formReset.checkValidity() && this.recaptchaVerified) {
        // Log.info('valid -> ora posso richiedere il reset')
        this.toggleLoader(true);
        try {
          const res = await this.$api.resetPassword(
            this.datiReset,
            this.recaptchaToken
          );
          this.toggleLoader(false);
          if (res.status == StatusCodes.OK) {
            // Log.info("registrazione avvenuta - salvo l'utente nello stato applicazione", res.body)
            this.showResetSuccess();
            setTimeout(() => {
              location.href = location.pathname;
            }, 1000);
          } else {
            let r = await res.json();
            // this.$log.warn("errore registrazione - notifico all'utente", r, this.$log.levels.ERROR)
            // notifica errore registrazione

            if (r.message.split(" - ")[1] === LINK_SCADUTO) {
              this.showResetError({
                title: "Link scaduto",
                message: this.getError(r.message),
                didClose: () => this.$router.push("/modifica-credenziali"),
              });
            } else {
              this.showResetError({ message: this.getError(r.message) });
            }
          }
        } catch (e) {
          this.toggleLoader(false);
          this.showResetError({
            title: "Errore di rete",
            message:
              "Non è stato possibile contattare il server. Controllare la connessione",
          });
        }
      } else this.$log.warn("dati non validi non invio nulla");
    },
  },
  notifications: {
    showResetSuccess: {
      title: "Credenziali impostate",
      message:
        "Il reset delle credenziali è completo. La password indicata può essere utilizzata per accedere al servizio",
      type: "success",
    },
    showResetError: {
      title: "Errore reset credenziali",
      message:
        "Il server ha restituito un errore durante l'operazione di reset delle credenziali",
      type: "error",
    },
  },
};
</script>
<style src="@/styles/custom/views/_reset.scss" scoped lang="scss"></style>